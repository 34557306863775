import MapboxDraw from "@mapbox/mapbox-gl-draw";
import Swal from "sweetalert2";
import Config from "../config/constants";
import geofenceIds from "./map";
import {geofenceAjaxCall, machineZoneAjaxCall} from "./map_ajax";
import {closeMenu} from "./map_filter_toggle";

let styles = [
    // ACTIVE (being drawn)
    // line stroke
    {
        "id": "gl-draw-line",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#1c1c1c",
            "line-dasharray": [0.2, 2],
            "line-width": 2
        }
    },
    // polygon fill
    {
        "id": "gl-draw-polygon-fill",
        "type": "fill",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "paint": {
            "fill-color": "#1c1c1c",
            "fill-outline-color": "#1c1c1c",
            "fill-opacity": 0.5
        }
    },
    // polygon mid points
    {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        "id": "gl-draw-polygon-stroke-active",
        "type": "line",
        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        "layout": {
            "line-cap": "round",
            "line-join": "round"
        },
        "paint": {
            "line-color": "#1c1c1c",
            "line-dasharray": [0.2, 2],
            "line-width": 2
        }
    },
    // vertex point halos
    {
        "id": "gl-draw-polygon-and-line-vertex-halo-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 5,
            "circle-color": "#FFF"
        }
    },
    // vertex points
    {
        "id": "gl-draw-polygon-and-line-vertex-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        "paint": {
            "circle-radius": 3,
            "circle-color": "#1c1c1c",
        }
    },
]
const draw = new MapboxDraw({
    displayControlsDefault: false,
    styles: styles
});

function listenForNewZone(map, site_id, geofence_ids) {
    if(document.getElementById('new-zone-popout-menu')) {
        listenForCancel(map);
        listenForViewData(map, site_id, null);
        listenForSaveAndPublish(map, site_id, geofence_ids);
    }

    map.on('draw.create', updateArea);
    map.on('draw.delete', updateArea);
    map.on('draw.update', updateArea);

    let polygon_button = document.getElementById('polygon-button');
    polygon_button.onclick = function () {
        let zone_type = document.getElementById('zone_type').value;
        if (zone_type == 'Access Zone'){
            styles[0]["paint"]["line-color"] = '#da0063'
            styles[1]["paint"]["fill-color"] = '#da0063'
            styles[1]["paint"]["fill-outline-color"] = '#da0063'
        }
        if (zone_type == 'Time In Zone'){
            styles[0]["paint"]["line-color"] = '#652cb3'
            styles[1]["paint"]["fill-color"] = '#652cb3'
            styles[1]["paint"]["fill-outline-color"] = '#652cb3'
        }
        draw.styles = styles;
        map.addControl(draw);
        draw.changeMode('draw_polygon');
        polygon_button.disabled = true;
        map.getCanvas().style.cursor = 'crosshair';
        $("#polygon-button").hide();
        $("#delete-button").attr("style", "display: block !important");
    }

    let delete_button = document.getElementById('delete-button');
    delete_button.onclick = function () {
        try {
            draw.deleteAll();
            map.removeControl(draw);
        } catch (TypeError) {
            console.log("No zone to delete");
        }
        $("#num_personnel").text("0");
        $("#num_machines").text("0");
        $("#num_interactions").text("0");
        $("#polygon-button").show();
        $("#delete-button").attr("style", "display: none !important");
        polygon_button.disabled = false;
        document.getElementById('geofence_array').value = '';
        map.getCanvas().style.cursor = 'grab';
    }

    function updateArea(e) {
        const data = draw.getAll();
        if (data.features.length > 0) {
            let array_of_long_lats = data.features[0].geometry.coordinates;
            document.getElementById('geofence_array').value = array_of_long_lats;
        }
        map.getCanvas().style.cursor = 'grab';
    }

    return geofence_ids;
}

function listenForViewData(map, site_id) {
    document.getElementById('new-zone-get-data-button').onclick = function() {
        get_zone_data(map, site_id, false, null)
    }
}

function listenForCancel(map){
    document.getElementById('new-zone-cancel').onclick = function() {
        let polygon_button = document.getElementById('polygon-button');
        polygon_button.disabled = false;
        try {
            draw.deleteAll();
            map.removeControl(draw);
        } catch (TypeError) {
            console.log("No zone to delete");
        }
        $("#num_personnel").text("0");
        $("#num_machines").text("0");
        $("#num_interactions").text("0");
        $("#polygon-button").show();
        $("#delete-button").attr("style", "display: none !important");
        document.getElementById('geofence_array').value = '';
        $("#new-zone-name").val('');
        closeMenu('new-zone-popout-menu');
    }
}

function listenForSaveAndPublish(map, site_id, geofence_ids) {
    document.getElementById('new-zone-save').onclick = function() {
        Swal.fire({
            title: 'Create Zone and Publish?',
            text: "Are you sure you want to save the zone and publish it's interaction data?",
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Do you want the zone to alert users?',
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        get_zone_data(map, site_id, true, true);
                        $("#polygon-button").show();
                        $("#delete-button").attr("style", "display: none !important");
                        $("#polygon-button").prop('disabled', false);
                    } else if (result.isDenied) {
                        get_zone_data(map, site_id, true, false);
                        $("#polygon-button").show();
                        $("#delete-button").attr("style", "display: none !important");
                        $("#polygon-button").prop('disabled', false);
                    } else {
                        Swal.fire('Zone not saved.')
                    }
                })
            }
        })
    }
}

function get_zone_data(map, site_id, should_save, is_alerting) {
    let fence = document.getElementById('geofence_array').value;
    let zone_name = document.getElementById('new-zone-name').value;
    let zone_type = document.getElementById('zone_type').value;
    let from_date = document.getElementById('filter-date-from').value;
    let to_date = document.getElementById('filter-date-to').value;
    let user_select = document.getElementById('new_zone_user_select');
    let machine_types = document.getElementById('new_zone_machine_select');

    let user_ids = getSelectizedChildren(user_select);
    console.log("user_ids: ", user_ids)
    let machine_ids = getSelectizedChildren(machine_types);

    if (fence == null || fence == undefined || fence == '') {
        Swal.fire('Draw a zone to view data.')
        return
    }

    if (should_save && (zone_name == '' || zone_name == undefined || zone_name == ' ')) {
        Swal.fire('Please provide a name for the area.')
        return
    }

    $.ajax({
        type: 'GET',
        url: `/api/v2/geofences/get_live_map_data?apikey=${Config.apiKey}&site_id=${site_id}&fence=${fence}&from_date=${from_date}&to_date=${to_date}&zone_type=${zone_type}&personnel=${user_ids}&machines=${machine_ids}&should_save=${should_save}&zone_name=${zone_name}&is_alerting=${is_alerting}`,
        dataType: "json",
        success: data => {
            if (zone_type == 'Access Zone') {
                $("#num_personnel").text(data.num_personnel);
                $("#num_machines").text(data.num_machines);
                $("#num_interactions").text(data.interactions);

                if (should_save) {
                    let range_date_param = document.getElementById('date_range_param').value;
                    if (data.new_personnel_zone_id != null) {
                        let combined_geofences = document.getElementById('geofences').value + " " + data.new_personnel_zone_id.toString();
                        geofenceAjaxCall(map, combined_geofences, range_date_param, null, true, false, false, false, null, false, null, false, false, null, null, null)
                        document.getElementById('geofences').value = combined_geofences;
                    }

                    if (data.new_machine_zone_id != null) {
                        let combined_machine_zones = document.getElementById('machine_zone_ids').value + " " + data.new_machine_zone_id.toString();
                        machineZoneAjaxCall(map, range_date_param, combined_machine_zones, false, true, null, null, null);
                        document.getElementById('machine_zone_ids').value = combined_machine_zones;
                    }

                    map.removeControl(draw);
                    document.getElementById('geofence_array').value = '';
                }
            }
        },
        error: data => {
            Swal.fire(data.responseJSON.error_message);
        }
    })
}

function getSelectizedChildren(select) {
    let children = select.children;
    let ids = [];
    for (let i = 0; i < children.length; i++) {
        ids.push(children[i].value.split(","));
    }

    return ids
}

export { listenForNewZone }