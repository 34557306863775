import Swal from "sweetalert2";
import Config from "./config/constants";

$(document).on('turbolinks:load', () => {
    let machines_zones_table = $("#machines_zones_table");
    if (machines_zones_table) {
        listenForMouseOver();
        listenForClick();
    }
})

function listenForClick() {
    $('.non-alerting-machine-zone').on("click", function() {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to make this an alerting zone? This will mean machines entering the zone will be alerted via their MachineSense.",
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                let zone_id = $(this).data('zone-id');
                $.ajax({
                    type: 'PUT',
                    url: `/api/v1/internal_machine_zones/${zone_id}/convert_to_alerting_zone?apikey=${Config.apiKey}`,
                    dataType: "json",
                    success: data => {
                        Swal.fire('Successfully converted to an alerting zone.');
                        location.reload();
                    }
                })
            }
        })
    })
}

function listenForMouseOver() {
    $('.non-alerting-machine-zone').on("mouseenter", function() {
        $(this).text('Make Alerting');
        $(this).css('cursor', 'pointer');
        $(this).removeClass('green-cell');
        $(this).addClass('personnel-zone-convert');
    })

    $('.non-alerting-machine-zone').on("mouseleave", function() {
        $(this).text('Allowed');
        $(this).css('cursor', 'default');
        $(this).addClass('green-cell');
        $(this).removeClass('personnel-zone-convert');
    })
}