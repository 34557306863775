import {closeMenu, openMenu} from "./map/map_filter_toggle";

document.addEventListener("turbolinks:load", () => {
    let new_zone_button = document.getElementById('new-zone-button');
    if (new_zone_button){
        toggleFilters(new_zone_button);
    }
});

const toggleFilters = (new_zone_button) => {
    // Opening map filters
    new_zone_button.onclick = function () {
        if($("#new-zone-popout-menu").is(":visible")){
            closeMenu('new-zone-popout-menu');
        }else{
            openMenu('new-zone-popout-menu');
        }
    }
}